var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.has_fake_baggage_ancillaries
    ? _c("div", { staticClass: "baggage" }, [
        _c("div", { staticClass: "headline" }, [
          _vm._v(_vm._s(_vm._f("locale")("Bagage:")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "passengers" },
          _vm._l(_vm.fake_baggage_ancillaries, function(bag_itinerary) {
            return _c("div", { staticClass: "passenger" }, [
              bag_itinerary.pax_type != "INF"
                ? _c("div", [
                    _c("div", { staticClass: "passenger_number" }, [
                      _vm._v(
                        _vm._s(
                          _vm.translatePax(
                            bag_itinerary,
                            _vm.itinerary.attributes.passenger_type
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "leg-container",
                        staticStyle: { width: "380px", float: "left" }
                      },
                      [
                        _c("div", { staticClass: "leg" }, [
                          _c(
                            "div",
                            {
                              staticClass: "ancillary",
                              staticStyle: {
                                display: "inline-block",
                                clear: "both"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: bag_itinerary.selected,
                                    expression: "bag_itinerary.selected"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  name: _vm.fetch_ancillary_id(bag_itinerary),
                                  id: _vm.fetch_ancillary_id(bag_itinerary),
                                  value: "1",
                                  "data-price": bag_itinerary.std_price_raw_pax,
                                  "data-price-pax":
                                    bag_itinerary.std_price_raw_pax,
                                  "data-ancillary-type": "fake"
                                },
                                domProps: {
                                  checked: Array.isArray(bag_itinerary.selected)
                                    ? _vm._i(bag_itinerary.selected, "1") > -1
                                    : bag_itinerary.selected
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = bag_itinerary.selected,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "1",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              bag_itinerary,
                                              "selected",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              bag_itinerary,
                                              "selected",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(bag_itinerary, "selected", $$c)
                                      }
                                    },
                                    function($event) {
                                      return _vm.$emit("change")
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: _vm.fetch_ancillary_id(bag_itinerary)
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.translateBag(
                                        bag_itinerary,
                                        "ancillary"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ])
                : _vm._e()
            ])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
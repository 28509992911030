var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.$store.getters.searching && _vm.filterData
        ? _c("div", { staticClass: "sort_by_group" }, [
            _c("ul", { staticClass: "sort_by" }, [
              _c(
                "li",
                {
                  class: { "ui-btn-active": _vm.filterOrderBy === "price" },
                  attrs: { "data-order-by": "price" },
                  on: {
                    click: function($event) {
                      return _vm.changeOrderBy("price")
                    }
                  }
                },
                [
                  _vm.filterData
                    ? [
                        _vm._v(
                          "\n          " + _vm._s(_vm._f("locale")("Billigast"))
                        ),
                        _c("br"),
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(_vm.filterData.min_price_pax)
                          )
                        ),
                        _c("div", { staticClass: "small_text" }, [
                          _vm._v(_vm._s(_vm._f("locale")("per person")))
                        ])
                      ]
                    : [
                        _vm._v(
                          "\n          " + _vm._s(_vm._f("locale")("Billigast"))
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "small_text" }, [_vm._v(" ")])
                      ]
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: {
                    "ui-btn-active": _vm.filterOrderBy === "traveltime"
                  },
                  attrs: { "data-order-by": "traveltime" },
                  on: {
                    click: function($event) {
                      return _vm.changeOrderBy("traveltime")
                    }
                  }
                },
                [
                  _vm.filterData
                    ? [
                        _vm._v(
                          "\n          " + _vm._s(_vm._f("locale")("Snabbast"))
                        ),
                        _c("br"),
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.filterData.min_traveltime_price_pax
                            )
                          )
                        ),
                        _c("div", { staticClass: "small_text" }, [
                          _vm._v(_vm._s(_vm._f("locale")("per person")))
                        ])
                      ]
                    : [
                        _vm._v(
                          "\n          " + _vm._s(_vm._f("locale")("Snabbast"))
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "small_text" }, [_vm._v(" ")])
                      ]
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: { "ui-btn-active": _vm.filterOrderBy === "best" },
                  attrs: { "data-order-by": "best" },
                  on: {
                    click: function($event) {
                      return _vm.changeOrderBy("best")
                    }
                  }
                },
                [
                  _vm.filterData
                    ? [
                        _vm._v(
                          "\n          " + _vm._s(_vm._f("locale")("Bäst"))
                        ),
                        _c("br"),
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(_vm.filterData.best_price_pax)
                          )
                        ),
                        _c("div", { staticClass: "small_text" }, [
                          _vm._v(_vm._s(_vm._f("locale")("per person")))
                        ])
                      ]
                    : [
                        _vm._v(
                          "\n          " + _vm._s(_vm._f("locale")("Bäst"))
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "small_text" }, [_vm._v(" ")])
                      ]
                ],
                2
              ),
              _vm._v(" "),
              _vm.metaButtonPrice
                ? _c(
                    "li",
                    {
                      class: { "ui-btn-active": _vm.filterOrderBy === "meta" },
                      attrs: { "data-order-by": "meta" },
                      on: {
                        click: function($event) {
                          return _vm.changeOrderBy("meta")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm._f("locale")("Ert val"))
                      ),
                      _c("br"),
                      _vm._v(_vm._s(_vm._f("currency")(_vm.metaButtonPrice))),
                      _c("div", { staticClass: "small_text" }, [
                        _vm._v(_vm._s(_vm._f("locale")("per person")))
                      ])
                    ]
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.legsCount < 3
        ? _c(
            "nav",
            {
              staticClass: "datepicker--nav",
              staticStyle: { "background-color": "#FFFFFF" }
            },
            [
              _c("div", { staticClass: "selectdate" }, [
                _c(
                  "div",
                  {
                    staticClass: "datepicker--nav-action",
                    on: {
                      click: function($event) {
                        return _vm.selectDate(1)
                      }
                    }
                  },
                  [
                    _c("svg", [
                      _c("path", { attrs: { d: "M 17,12 l -5,5 l 5,5" } })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "datepicker--nav-title" }, [
                  _vm._v(_vm._s(_vm.returnDate(0)))
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "datepicker--nav-action",
                    on: {
                      click: function($event) {
                        return _vm.selectDate(2)
                      }
                    }
                  },
                  [
                    _c("svg", [
                      _c("path", { attrs: { d: "M 14,12 l 5,5 l -5,5" } })
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.legsCount > 1
                ? _c("div", { staticClass: "selectdate" }, [
                    _c(
                      "div",
                      {
                        staticClass: "datepicker--nav-action",
                        on: {
                          click: function($event) {
                            return _vm.selectDate(3)
                          }
                        }
                      },
                      [
                        _c("svg", [
                          _c("path", { attrs: { d: "M 17,12 l -5,5 l 5,5" } })
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "datepicker--nav-title" }, [
                      _vm._v(_vm._s(_vm.returnDate(1)))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "datepicker--nav-action",
                        on: {
                          click: function($event) {
                            return _vm.selectDate(4)
                          }
                        }
                      },
                      [
                        _c("svg", [
                          _c("path", { attrs: { d: "M 14,12 l 5,5 l -5,5" } })
                        ])
                      ]
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.alternativeItinerary
        ? _c("itinerary-air-alternative", {
            key: "alt_" + _vm.$store.state.alternativeItinerary.id,
            attrs: { itinerary: _vm.$store.state.alternativeItinerary }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.itineraries.length === 0 &&
      !_vm.$store.getters.searching &&
      !_vm.loading
        ? _c("div", [
            _vm._v(
              "\n    " +
                _vm._s(_vm._f("locale")("Alla resultat är bortfiltrerade.")) +
                " "
            ),
            _c(
              "a",
              {
                attrs: { id: "reset-filter", href: "javascript:void(0);" },
                on: { click: _vm.resetFilter }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("locale")("Klicka här för att rensa alla filter")
                  )
                )
              ]
            ),
            _vm._v(".\n  ")
          ])
        : _c(
            "div",
            [
              _vm.$store.state.alternativeViewState === null
                ? [
                    _vm._l(_vm.itineraries, function(itinerary, index) {
                      return [
                        itinerary.attributes.template.id === 5
                          ? _c("itinerary-air-5", {
                              key: itinerary.id,
                              attrs: { itinerary: itinerary }
                            })
                          : itinerary.attributes.template.id === 4
                          ? _c("itinerary-air-4", {
                              key: itinerary.id,
                              attrs: { itinerary: itinerary }
                            })
                          : itinerary.attributes.template.id === 3
                          ? _c("itinerary-air-3", {
                              key: itinerary.id,
                              attrs: { itinerary: itinerary }
                            })
                          : itinerary.attributes.template.id === 2
                          ? _c("itinerary-air-2", {
                              key: itinerary.id,
                              attrs: { itinerary: itinerary }
                            })
                          : _c("itinerary-air-1", {
                              key: itinerary.id,
                              attrs: { itinerary: itinerary }
                            }),
                        _vm._v(" "),
                        _vm.hasBanners && index % 4 == 0
                          ? _c(
                              "div",
                              {
                                key: "banner_" + index,
                                staticClass: "banners_horizontal"
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href:
                                        _vm.banners[
                                          (index / 4) % _vm.banners.length
                                        ].link
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm._f(
                                          "cloudinary"
                                        )(
                                          "/assets/banners_horizontal/" +
                                            _vm.banners[
                                              (index / 4) % _vm.banners.length
                                            ].image,
                                          { e_trim: false }
                                        )
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    })
                  ]
                : [
                    _vm._l(_vm.itineraries, function(itinerary, index) {
                      return [
                        _c("itinerary-air-alternative", {
                          key: itinerary.id,
                          attrs: { itinerary: itinerary }
                        }),
                        _vm._v(" "),
                        _vm.hasBanners && index % 4 == 0
                          ? _c(
                              "div",
                              {
                                key: "banner_" + index,
                                staticClass: "banners_horizontal"
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href:
                                        _vm.banners[
                                          (index / 4) % _vm.banners.length
                                        ].link
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm._f(
                                          "cloudinary"
                                        )(
                                          "/assets/banners_horizontal/" +
                                            _vm.banners[
                                              (index / 4) % _vm.banners.length
                                            ].image,
                                          { e_trim: false }
                                        )
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    })
                  ]
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
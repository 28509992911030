import "./datepicker.da";
import "./datepicker.de";
import "./datepicker.en";
import "./datepicker.es";
import "./datepicker.fi";
import "./datepicker.is";
import "./datepicker.nl";
import "./datepicker.no";
import "./datepicker.pl";
import "./datepicker.ro";
import "./datepicker.se";
import "./datepicker.fr";

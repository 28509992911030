var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: _vm.componentID() }, on: { click: _vm.onClick } },
    [
      _c("input", {
        attrs: { type: "hidden", name: this.name },
        domProps: { value: this.currentValue }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "Switch",
          class: { Off: !this.currentValue, On: this.currentValue }
        },
        [
          _c("div", { staticClass: "Toggle" }),
          _vm._v(" "),
          _c("span", { staticClass: "On" }, [_vm._v("ON")]),
          _vm._v(" "),
          _c("span", { staticClass: "Off" }, [_vm._v("OFF")])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

const CLOUDINARY_API_KEY = "dzwdseno3";
const VERSION = new Date("2024-11-12 10:51").getTime();

/**
 * Create a Cloudinary link based on the parameters.
 *
 * @example
 * cloudinary("ABC", "image.png", { e_trim: false, h: 200 })
 *
 * @param {string} value The image.
 * @param {Object} args The transform arguments.
 * @return {string} The Cloudinary link.
 */
export function cloudinary(value, args = {}) {
    let inputArgs = {};
    let newValue = value;

    if (!newValue.endsWith(".gif")) {
        inputArgs = {
            c_fill: true,
            e_trim: true,
            f_auto: true,
            g_auto: true,
            q_auto: true,
        };
    }

    if (args) {
        for (let prop in args) {
            const propValue = args[prop];
            if (inputArgs.hasOwnProperty(prop)) {
                if (propValue) {
                    inputArgs[prop] = propValue;
                } else {
                    delete inputArgs[prop];
                }
            } else {
                if (propValue !== false) {
                    inputArgs[prop] = propValue;
                }
            }
        }
    }

    if (inputArgs['c_limit']) {
        delete inputArgs['c_fill'];
        delete inputArgs['g_auto'];
        delete inputArgs['e_trim'];
    }

    let result = "";
    for (let prop in inputArgs) {
        const propValue = inputArgs[prop];
        if (result.length > 0) {
            result += ","
        }

        if (propValue === true) {
            result += prop;
        } else {
            result += prop + "_" + propValue;
        }
    }

    if (!value.startsWith("http")) {
        newValue = `http://web.travex.se${newValue}`;
    }

    return `https://res.cloudinary.com/${CLOUDINARY_API_KEY}/image/fetch/${result}/v${VERSION}/${newValue}`;
}

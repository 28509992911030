var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "itinerary step_1_air_form itinerary_t1",
      attrs: {
        "data-uuid": _vm.itinerary.id,
        "data-uuid-raw": _vm.itinerary.attributes.uuid
      },
      on: { submit: _vm.submit }
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "uuid" },
        domProps: { value: _vm.itinerary.attributes.uuid }
      }),
      _vm._v(" "),
      _vm.itinerary.attributes.banned
        ? _c("div", { staticClass: "fly-schedule-overlay" }, [
            _c(
              "div",
              { staticClass: "fly-schedule-overlay-content" },
              [
                _vm.searchMeta
                  ? [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("locale")("falco_errors.itinerary.uc_meta")
                          ) +
                          "\n      "
                      )
                    ]
                  : [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("locale")("falco_errors.itinerary.uc")
                          ) +
                          "\n      "
                      )
                    ]
              ],
              2
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fly-schedule", class: { overlay: _vm.locked } },
        [
          _vm.itinerary.attributes.meta
            ? _c("div", { staticClass: "flight-message" }, [
                _vm._v(_vm._s(_vm._f("locale")("Ert val")))
              ])
            : _vm.itinerary.attributes.promote &&
              _vm.itinerary.attributes.promotion_headline
            ? _c("div", { staticClass: "flight-message" }, [
                _vm._v(_vm._s(_vm.itinerary.attributes.promotion_headline))
              ])
            : _vm.itinerary.attributes.promote &&
              !_vm.itinerary.attributes.promotion_headline
            ? _c("div", { staticClass: "flight-message" }, [
                _vm._v(_vm._s(_vm._f("locale")("Vi rekommenderar")))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.itinerary.attributes.debug
            ? [
                _vm.itinerary.banned
                  ? _c("div", { staticClass: "flight-message" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.itinerary.attributes.debug.banned_reason) +
                          "\n      "
                      )
                    ])
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fly-schedule-inner",
              class: {
                allotment:
                  _vm.itinerary.attributes.allotment_promo &&
                  !_vm.itinerary.attributes.allotment_promo.searched_date
              }
            },
            [
              _vm.itinerary.attributes.price_type == "agr"
                ? _c("div", { staticClass: "price_type" }, [
                    _vm._v("agreement fare")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.itinerary.attributes.allotment_promo &&
              !_vm.itinerary.attributes.allotment_promo.searched_date
                ? _c("div", { staticClass: "allotment-wrap" }, [
                    _c("p", [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.allotmentDatesText())
                        }
                      }),
                      _vm._v(" "),
                      _c("b", { staticClass: "dates-highlight" }, [
                        _vm._v(
                          _vm._s(_vm.itinerary.attributes.allotment_promo.dates)
                        )
                      ]),
                      _vm._v(" "),
                      _vm.itinerary.attributes.allotment_promo.save
                        ? _c("span", { staticClass: "price-tag" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm._f("locale")("allotment.save")) +
                                " "
                            ),
                            _c("u", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.itinerary.attributes.allotment_promo
                                      .save
                                  )
                                )
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "flight-wrap" }, [
                _c(
                  "div",
                  { staticClass: "flight" },
                  [
                    _vm._l(_vm.itinerary.attributes.carriers, function(
                      carrier
                    ) {
                      return _c("img", {
                        key: _vm.itinerary.id + "_" + carrier.code,
                        attrs: {
                          src: _vm._f("cloudinary")(
                            "/assets/airlines/" + carrier.code + ".png",
                            { w: 30, h: 30, c_limit: true }
                          )
                        }
                      })
                    }),
                    _vm._v(" "),
                    _vm.itinerary.attributes.carriers.length === 1
                      ? _c("div", { staticClass: "name" }, [
                          _vm._v(
                            _vm._s(_vm.itinerary.attributes.carriers[0].name)
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "extra-links" }, [
                  _c("div", { staticClass: "flight-rules" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "#",
                          fancybox: "",
                          "data-src": _vm.generateTicketrulesPath(
                            _vm.itinerary.attributes.uuid
                          ),
                          "data-type": "ajax"
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm._f("cloudinary")(
                              "/assets/shared/icons/rules.png"
                            )
                          }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("locale")("Biljettregler")) +
                            "\n            "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flydetail" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "#",
                          fancybox: "",
                          "data-src": _vm.generatePriceCalculationPath(
                            _vm.itinerary.attributes.uuid
                          ),
                          "data-type": "ajax"
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm._f("cloudinary")(
                              "/assets/flygcity_responsive/addon_GARANTI.png"
                            )
                          }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("locale")("Prisberäkning")) +
                            "\n            "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.itinerary.attributes.search_type == "youth"
                    ? _c("div", { staticClass: "flydetail" }, [
                        _vm.itinerary.attributes.passenger_type == "YTH"
                          ? _c("div", { staticClass: "youth_price" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm._f("locale")("Ungdomspris")) +
                                  "\n            "
                              )
                            ])
                          : _c("div", { staticClass: "youth_price" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm._f("locale")("Vuxenpris")) +
                                  "\n            "
                              )
                            ])
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "price-box" }, [
                  _vm.itinerary.attributes.price_month
                    ? _c(
                        "a",
                        {
                          staticClass: "resurs",
                          attrs: {
                            href: "#",
                            fancybox: "",
                            "data-type": "ajax",
                            "data-src": _vm.month_price_url,
                            "data-options": "{'touch':false,'baseClass':null}"
                          }
                        },
                        [
                          _c("div", { staticClass: "resurs_text" }, [
                            _vm._v(_vm._s(_vm._f("locale")("Delbetala fr.")))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resurs_price" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("locale")(
                                  "%{amount}/mån",
                                  _vm.itinerary.attributes.price_month
                                )
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.itinerary.attributes.price_pax
                    ? _c("div", { staticClass: "pax-price" }, [
                        _c("div", { staticClass: "price" }, [
                          _vm._v(_vm._s(_vm.itinerary.attributes.price_pax))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "smallprice" }, [
                          _vm._v(_vm._s(_vm._f("locale")("per person")))
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "total-price" }, [
                    _c("div", { staticClass: "price" }, [
                      _vm._v(_vm._s(_vm.itinerary.attributes.price))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "smallprice" }, [
                      _vm._v(_vm._s(_vm._f("locale")("totalt inkl.skatt")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "clear" })
              ]),
              _vm._v(" "),
              _vm.itinerary.attributes.seats < 9
                ? _c("div", { staticClass: "places-left" }, [
                    _vm._v(
                      _vm._s(_vm.placesLeftText(_vm.itinerary.attributes.seats))
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasCabins()
                ? _c("div", { staticClass: "cabin-info" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.allCabinsText()) + "\n        "
                    ),
                    _c("span", [
                      _vm._v("("),
                      _c(
                        "span",
                        {
                          staticClass: "link",
                          attrs: {
                            tooltip: "",
                            "data-tooltip-content":
                              "#cabin-tooltip-" + _vm.itinerary.id
                          }
                        },
                        [_vm._v(_vm._s(_vm._f("locale")("mer information")))]
                      ),
                      _vm._v(")")
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.itinerary.attributes.textrule
                ? _c("div", { staticClass: "text-info" }, [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "link",
                          attrs: {
                            tooltip: "",
                            "data-tooltip-content":
                              "#text-tooltip-" + _vm.itinerary.id
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.itinerary.attributes.textrule.headline)
                          )
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.itinerary.attributes.itinerary, function(
                itin,
                itinIndex
              ) {
                return _c(
                  "div",
                  {
                    key: _vm.itinerary.id + "_" + itinIndex,
                    staticClass: "flight-sec-wrap"
                  },
                  [
                    _c("div", { staticClass: "flight-sc" }, [
                      _c("ul", { staticClass: "table-header" }, [
                        _c("li", { staticClass: "li_1" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("locale")("Resa %{num}", itinIndex + 1)
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "li_2" }, [
                          _vm._v(_vm._s(_vm._f("locale")("Från")))
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "li_3" }, [
                          _vm._v(_vm._s(_vm._f("locale")("Ankomst")))
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "li_4" }, [
                          _vm._v(_vm._s(_vm._f("locale")("Till")))
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "li_5" }, [
                          _vm._v(_vm._s(_vm._f("locale")("Flygs av")))
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "li_6" }, [
                          _vm._v(_vm._s(_vm._f("locale")("Flight")))
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "li_7" }, [
                          _vm._v(_vm._s(_vm._f("locale")("Bagage")))
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "li_8" }, [
                          _vm._v(_vm._s(_vm._f("locale")("Restid")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("ul", { staticClass: "outbound" }, [
                        _c(
                          "li",
                          { staticClass: "flight-sc-layout" },
                          [
                            itin.change_airport
                              ? _c(
                                  "div",
                                  {
                                    key:
                                      _vm.itinerary.id +
                                      "_" +
                                      itinIndex +
                                      "_change_airport",
                                    staticClass:
                                      "time-box change-airport change-airport-itinerary"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(itin.change_airport) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(itin.segments, function(
                              segment,
                              segmentIndex
                            ) {
                              return [
                                segment.recheck_bags
                                  ? _c(
                                      "div",
                                      {
                                        key:
                                          _vm.itinerary.id +
                                          "_" +
                                          itinIndex +
                                          "_" +
                                          segmentIndex +
                                          "_change_airport",
                                        staticClass: "time-box change-airport",
                                        attrs: {
                                          tooltip: "",
                                          title: _vm._f("locale")(
                                            "Er resa består av två eller flera biljetter, vilket innebär att ni bokar två eller flera separata biljetter.<br /><br />Du behöver därför checka in dig själv och eventuellt bagage igen mellan de flygningar som är på separata biljetter.<br /><br />Om en av dina flygningar ställer in av någon anledning och inte genomförs som planerat så ändras/avbokas INTE den/de andra flygningen/flygningarna per automatik.<br /><br />Ni ansvarar själva för eventuell ombokning och står själva för eventuella merkostnader."
                                          )
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm._f("cloudinary")(
                                              "/assets/shared/icons/rules.png"
                                            )
                                          }
                                        }),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("locale")(
                                                "Hämta ut och checka in dig och ditt bagage igen!"
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                segment.change_airport
                                  ? _c(
                                      "div",
                                      {
                                        key:
                                          _vm.itinerary.id +
                                          "_" +
                                          itinIndex +
                                          "_" +
                                          segmentIndex +
                                          "_change_airport",
                                        staticClass: "time-box change-airport"
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(segment.change_airport))
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("locale")(
                                                "Hämta ut och checka in dig och ditt bagage igen!"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    key:
                                      _vm.itinerary.id +
                                      "_" +
                                      itinIndex +
                                      "_" +
                                      segmentIndex,
                                    staticClass: "time-box",
                                    class: {
                                      first: segmentIndex === 0,
                                      last:
                                        segmentIndex ===
                                        itin.segments.length - 1
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "time-1" }, [
                                      _vm._v(_vm._s(segment.dep_time))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "time-dep_airport",
                                        class: {
                                          "time-technical-stop":
                                            segment.origin_nearby
                                        },
                                        attrs: {
                                          tooltip: "",
                                          title: segment.origin
                                        }
                                      },
                                      [
                                        segment.type === "TRN"
                                          ? _c("i", {
                                              staticClass: "fa fa-train"
                                            })
                                          : segment.type === "BUS"
                                          ? _c("i", {
                                              staticClass: "fa fa-bus"
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          "\n\n                    " +
                                            _vm._s(segment.origin || " ") +
                                            "\n\n                    "
                                        ),
                                        segment.origin_nearby
                                          ? _c(
                                              "div",
                                              { staticClass: "airport_change" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm._f("locale")(
                                                        "OBS Närliggande flygplats!"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "time-2" }, [
                                      _vm._v(_vm._s(segment.arr_time))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "time-arr_airport",
                                        class: {
                                          "time-technical-stop":
                                            segment.destination_nearby ||
                                            segment.tech_stop
                                        },
                                        attrs: {
                                          tooltip: "",
                                          title: segment.destination
                                        }
                                      },
                                      [
                                        segment.type === "TRN"
                                          ? _c("i", {
                                              staticClass: "fa fa-train"
                                            })
                                          : segment.type === "BUS"
                                          ? _c("i", {
                                              staticClass: "fa fa-bus"
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          "\n\n                    " +
                                            _vm._s(segment.destination || " ") +
                                            "\n\n                    "
                                        ),
                                        segment.destination_nearby
                                          ? _c(
                                              "div",
                                              { staticClass: "airport_change" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm._f("locale")(
                                                        "OBS Närliggande flygplats!"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "time-carrier",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.opCarrierText(segment.op_carrier)
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "time-flightnumber",
                                        attrs: {
                                          tooltip: "",
                                          title: _vm.generateFlightnumberTooltip(
                                            segment
                                          )
                                        }
                                      },
                                      [_vm._v(_vm._s(segment.flight))]
                                    ),
                                    _vm._v(" "),
                                    segment.type === "AIR"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "time-baggage",
                                            attrs: {
                                              tooltip: "",
                                              title: _vm.generateBaggageTooltip(
                                                segment
                                              )
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { class: segment.baggage.type },
                                              [
                                                _vm._v(
                                                  _vm._s(segment.baggage.text)
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "time-baggage" },
                                          [
                                            _c(
                                              "span",
                                              { class: segment.baggage.type },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("locale")(
                                                      "Inkluderat"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                  ]
                                )
                              ]
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "time-travel_time" }, [
                              _vm._v(_vm._s(itin.travel_time))
                            ]),
                            _vm._v(" "),
                            itin.tech_stops
                              ? _c(
                                  "div",
                                  { staticClass: "flight-technical-stop" },
                                  [_vm._v("* " + _vm._s(itin.tech_stops))]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    ])
                  ]
                )
              }),
              _vm._v(" "),
              _vm.itinerary.attributes.itinerary.some(function(itin) {
                return itin.segments.some(function(segment) {
                  return segment.recheck_bags
                })
              })
                ? _c(
                    "div",
                    {
                      staticClass: "multiple_tickets",
                      attrs: {
                        tooltip: "",
                        title: _vm._f("locale")(
                          "Er resa består av två eller flera biljetter, vilket innebär att ni bokar två eller flera separata biljetter.<br /><br />Du behöver därför checka in dig själv och eventuellt bagage igen mellan de flygningar som är på separata biljetter.<br /><br />Om en av dina flygningar ställer in av någon anledning och inte genomförs som planerat så ändras/avbokas INTE den/de andra flygningen/flygningarna per automatik.<br /><br />Ni ansvarar själva för eventuell ombokning och står själva för eventuella merkostnader."
                        )
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm._f("cloudinary")(
                            "/assets/shared/icons/rules.png"
                          )
                        }
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("locale")(
                              "Resan består av två eller flera biljetter,"
                            )
                          ) +
                          " "
                      ),
                      _c(
                        "span",
                        { staticStyle: { "text-decoration": "underline" } },
                        [_vm._v(_vm._s(_vm._f("locale")("läs mer")))]
                      ),
                      _vm._v(".\n      ")
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.itinerary.attributes.multi
                ? _c(
                    "div",
                    {
                      staticClass: "multiple_tickets",
                      attrs: {
                        tooltip: "",
                        title: _vm._f("locale")(
                          "Er resa består av två eller flera enkelresor, vilket innebär att ni bokar två eller flera separata biljetter.<br /><br />Om en av dina flygningar ställer in av någon anledning och inte genomförs som planerat så ändras/avbokas INTE den/de andra flygningen/flygningarna per automatik.<br /><br />Ni ansvarar själva för eventuell ombokning och står själva för eventuella merkostnader."
                        )
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm._f("cloudinary")(
                            "/assets/shared/icons/rules.png"
                          )
                        }
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("locale")("Resan består av enkla biljetter,")
                          ) +
                          " "
                      ),
                      _c(
                        "span",
                        { staticStyle: { "text-decoration": "underline" } },
                        [_vm._v(_vm._s(_vm._f("locale")("läs mer")))]
                      ),
                      _vm._v(".\n      ")
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.itinerary.attributes.cfar
                ? _c("Cfar", {
                    attrs: { itinerary: _vm.itinerary },
                    on: { change: _vm.bagsChange }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.itinerary.attributes.climate
                ? _c(
                    "div",
                    {
                      staticClass: "climate",
                      attrs: {
                        tooltip: "",
                        title: _vm.climateTooltipText,
                        "tooltip-max-width": "300"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.climateSelected,
                            expression: "climateSelected"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          name: "climate",
                          id: _vm.itinerary.id + "_climate",
                          value: "1",
                          "data-price": _vm.itinerary.attributes.climate.price,
                          "data-price-pax": _vm.itinerary.attributes.climate.pax
                        },
                        domProps: {
                          checked: Array.isArray(_vm.climateSelected)
                            ? _vm._i(_vm.climateSelected, "1") > -1
                            : _vm.climateSelected
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.climateSelected,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "1",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.climateSelected = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.climateSelected = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.climateSelected = $$c
                              }
                            },
                            _vm.priceChange
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: _vm.itinerary.id + "_climate" } },
                        [
                          _vm._v(
                            _vm._s(_vm.itinerary.attributes.climate.text) +
                              " (" +
                              _vm._s(
                                _vm.itinerary.attributes.climate.emission
                              ) +
                              " kg CO"
                          ),
                          _c("sub", [_vm._v("2")]),
                          _vm._v(")")
                        ]
                      ),
                      _vm._v(". "),
                      _c(
                        "span",
                        { staticStyle: { "text-decoration": "underline" } },
                        [_vm._v(_vm._s(_vm._f("locale")("läs mer")))]
                      ),
                      _vm._v(".\n      ")
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.itinerary.attributes.baggage_ancillaries
                ? _c("Baggage", {
                    attrs: { itinerary: _vm.itinerary },
                    on: { change: _vm.bagsChange }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showSubmit
                ? _c(
                    "div",
                    { staticClass: "continue-button" },
                    [
                      _vm.selected
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "spinner",
                                staticStyle: { "text-align": "center" }
                              },
                              [
                                _vm.itinerary.attributes.allotment_promo
                                  ? _c("img", {
                                      attrs: {
                                        src: _vm._f("cloudinary")(
                                          "/assets/shared/AjaxLoader.gif"
                                        )
                                      }
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: _vm._f("cloudinary")(
                                          "/assets/shared/ajax-loader_white.gif"
                                        )
                                      }
                                    })
                              ]
                            )
                          ]
                        : [
                            _c(
                              "div",
                              { staticClass: "y-button y-button-padding-0-2" },
                              [
                                _c("input", {
                                  staticClass: "fly",
                                  attrs: { type: "submit", name: "commit" },
                                  domProps: { value: _vm.submitText }
                                })
                              ]
                            )
                          ]
                    ],
                    2
                  )
                : _vm._e()
            ],
            2
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { visibility: "collapse", width: "0", height: "0" } },
        [
          _c(
            "div",
            {
              staticClass: "tip_cabin",
              attrs: { id: "cabin-tooltip-" + _vm.itinerary.id }
            },
            [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "from" }, [
                  _vm._v(_vm._s(_vm._f("locale")("Från")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "to" }, [
                  _vm._v(_vm._s(_vm._f("locale")("Till")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cabin" }, [
                  _vm._v(_vm._s(_vm._f("locale")("Biljettyp")))
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.allFlights(_vm.itinerary.attributes), function(
                flight,
                flightIndex
              ) {
                return _c(
                  "div",
                  {
                    key:
                      "cabin-tooltip-" + _vm.itinerary.id + "-" + flightIndex,
                    staticClass: "row"
                  },
                  [
                    _c("div", { staticClass: "from" }, [
                      _vm._v(_vm._s(flight.origin))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "to" }, [
                      _vm._v(_vm._s(flight.destination))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cabin" }, [
                      _vm._v(_vm._s(flight.cabin))
                    ])
                  ]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _vm.itinerary.attributes.textrule
            ? _c(
                "div",
                {
                  staticClass: "tip_text",
                  attrs: { id: "text-tooltip-" + _vm.itinerary.id }
                },
                [
                  _c("div", { staticClass: "header" }, [
                    _vm._v(_vm._s(_vm.itinerary.attributes.textrule.headline))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "body" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.itinerary.attributes.textrule.text
                        )
                      }
                    })
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: _vm.componentID() } }, [
    _c("input", {
      attrs: { type: "hidden", name: this.name },
      domProps: { value: this.currentValue }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "slider_component" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
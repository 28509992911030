var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.has_cfar_ancillaries
    ? _c("div", { staticClass: "baggage" }, [
        _c("div", { staticClass: "headline" }, [
          _vm._v(_vm._s(_vm._f("locale")("Fri avbokning:")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "passengers" },
          _vm._l(_vm.itinerary.attributes.cfar, function(cfar, index) {
            return _c("div", { staticClass: "passenger" }, [
              _c(
                "div",
                {
                  staticClass: "passenger_number",
                  staticStyle: { display: "inline-block", width: "600px" },
                  attrs: {
                    tooltip: "",
                    title: _vm.cfarTooltipText(cfar.formatted_price)
                  }
                },
                [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      name: _vm.cfarName(index),
                      id: _vm.cfarName(index),
                      value: "1",
                      "data-price": cfar.price,
                      "data-price-pax": cfar.price,
                      "data-ancillary-type": cfar
                    },
                    domProps: { checked: _vm.cfarSelected(cfar) },
                    on: {
                      change: function($event) {
                        return _vm.$emit("change")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: _vm.cfarName(index) } }, [
                    _vm._v(_vm._s(_vm.translatePax(_vm.itinerary, cfar)))
                  ])
                ]
              )
            ])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
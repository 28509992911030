var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "10px" } },
    [
      _c(
        "div",
        {
          staticStyle: {
            width: "49%",
            display: "inline-block",
            "vertical-align": "top"
          }
        },
        [
          _c("h4", { staticStyle: { "font-size": "18px" } }, [
            _vm._v("Presentationrules")
          ]),
          _vm._v(" "),
          _c(
            "table",
            _vm._l(Object.keys(_vm.info.presentationrules), function(key) {
              return _c("tr", { key: key }, [
                _c("td", { staticStyle: { "padding-right": "10px" } }, [
                  _vm._v(_vm._s(key))
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.info.presentationrules[key]))])
              ])
            }),
            0
          )
        ]
      ),
      _vm._v(" "),
      _vm.info.itinerary && _vm.info.itinerary.data.attributes.template
        ? [
            _c(
              "div",
              {
                staticStyle: {
                  width: "49%",
                  display: "inline-block",
                  "vertical-align": "top"
                }
              },
              [
                _c("h4", { staticStyle: { "font-size": "18px" } }, [
                  _vm._v("Itinerary Presentationrules")
                ]),
                _vm._v(" "),
                _c(
                  "table",
                  _vm._l(
                    Object.keys(_vm.info.itinerary.data.attributes.template),
                    function(key) {
                      return _c("tr", { key: key }, [
                        _c("td", { staticStyle: { "padding-right": "10px" } }, [
                          _vm._v(_vm._s(key))
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.info.itinerary.data.attributes.template[key]
                            )
                          )
                        ])
                      ])
                    }
                  ),
                  0
                )
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
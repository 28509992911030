var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fake-itinerary" }, [
      _c("div", { staticClass: "fake-itinerary-airline" }),
      _vm._v(" "),
      _c("div", { staticClass: "fake-itinerary-price" }),
      _vm._v(" "),
      _c("div", { staticClass: "fake-itinerary-container" }, [
        _c("table", { staticClass: "fake-itinerary-itinerary" }, [
          _c("tr", [
            _c("td", { staticClass: "fake-itinerary-item" }),
            _vm._v(" "),
            _c("td", { staticClass: "fake-itinerary-br" }),
            _vm._v(" "),
            _c("td", { staticClass: "fake-itinerary-item" })
          ]),
          _vm._v(" "),
          _c("tr", { staticClass: "fake-itinerary-br" }),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "fake-itinerary-item" }),
            _vm._v(" "),
            _c("td", { staticClass: "fake-itinerary-br" }),
            _vm._v(" "),
            _c("td", { staticClass: "fake-itinerary-item" })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fake-itinerary-br" }),
        _vm._v(" "),
        _c("table", { staticClass: "fake-itinerary-itinerary" }, [
          _c("tr", [
            _c("td", { staticClass: "fake-itinerary-item" }),
            _vm._v(" "),
            _c("td", { staticClass: "fake-itinerary-br" }),
            _vm._v(" "),
            _c("td", { staticClass: "fake-itinerary-item" })
          ]),
          _vm._v(" "),
          _c("tr", { staticClass: "fake-itinerary-br" }),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "fake-itinerary-item" }),
            _vm._v(" "),
            _c("td", { staticClass: "fake-itinerary-br" }),
            _vm._v(" "),
            _c("td", { staticClass: "fake-itinerary-item" })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fake-itinerary-button" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
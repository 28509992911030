var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll",
          value: _vm.handleScroll,
          expression: "handleScroll"
        }
      ],
      staticClass: "search-body"
    },
    [
      _c(
        "div",
        { staticClass: "search-body-filter" },
        [
          _vm.hasDiscount
            ? _c(
                "div",
                {
                  staticClass: "internet-bank-text",
                  staticStyle: { "text-align": "center" }
                },
                [
                  _c("span", { attrs: { title: _vm.discountTitle } }, [
                    _vm._v(_vm._s(_vm.discountContent))
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.getters.searching
            ? [_vm._m(0)]
            : [
                _c(
                  "keep-alive",
                  [
                    _vm.$store.getters.viewState === 1
                      ? _c("search-filter-hotel")
                      : _c("search-filter-flight")
                  ],
                  1
                )
              ]
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-body-results" },
        [
          _vm.$store.getters.searching
            ? [
                _vm.$store.getters.viewState === 1
                  ? [
                      _c("itinerary-hotel-fake"),
                      _vm._v(" "),
                      _c("itinerary-hotel-fake")
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.$store.getters.viewState === 0
                  ? [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("itinerary-air-fake"),
                      _vm._v(" "),
                      _c("itinerary-air-fake")
                    ]
                  : _vm._e()
              ]
            : [
                _c(
                  "keep-alive",
                  [
                    _vm.$store.getters.viewState === 1
                      ? _c("step-1-results-hotel")
                      : _c("step-1-results-air")
                  ],
                  1
                )
              ]
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { ref: "banner", staticClass: "search-body-banner" }, [
        _vm.vbanner && _vm.vbanner.length > 0
          ? _c(
              "div",
              { staticClass: "banner-container" },
              _vm._l(_vm.vbanner, function(banner) {
                return _c(
                  "a",
                  {
                    key: banner.image,
                    attrs: { href: banner.link, target: "_blank" }
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        "margin-bottom": "10px",
                        "margin-right": "10px"
                      },
                      attrs: {
                        src: _vm._f("cloudinary")("/assets/" + banner.image, {
                          e_trim: false
                        })
                      }
                    })
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fake-filter" }, [
      _c("div", { staticClass: "fake-filter-header" }),
      _vm._v(" "),
      _c("div", { staticClass: "fake-filter-item" }),
      _vm._v(" "),
      _c("div", { staticClass: "fake-filter-item" }),
      _vm._v(" "),
      _c("div", { staticClass: "fake-filter-header" }),
      _vm._v(" "),
      _c("div", { staticClass: "fake-filter-item" }),
      _vm._v(" "),
      _c("div", { staticClass: "fake-filter-item" }),
      _vm._v(" "),
      _c("div", { staticClass: "fake-filter-item" }),
      _vm._v(" "),
      _c("div", { staticClass: "fake-filter-header" }),
      _vm._v(" "),
      _c("div", { staticClass: "fake-filter-item" }),
      _vm._v(" "),
      _c("div", { staticClass: "fake-filter-item" }),
      _vm._v(" "),
      _c("div", { staticClass: "fake-filter-item" }),
      _vm._v(" "),
      _c("div", { staticClass: "fake-filter-item" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fake-sort_by_group-container" }, [
      _c("div", { staticClass: "sort_by_group" }, [
        _c("ul", { staticClass: "sort_by" }, [
          _c("li", [_c("div")]),
          _vm._v(" "),
          _c("li", [_c("div")]),
          _vm._v(" "),
          _c("li", [_c("div")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticStyle: { width: "290px" },
      attrs: { id: "filter_form" },
      on: { submit: _vm.onSubmit }
    },
    [
      _c("input", { attrs: { name: "utf8", type: "hidden", value: "✓" } }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "sequence", id: "sequence", value: "0" }
      }),
      _vm._v(" "),
      _c("input", {
        attrs: {
          type: "hidden",
          name: "filter_order_by",
          id: "filter_order_by",
          value: "price"
        }
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "meta_uuid", id: "meta_uuid" }
      }),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "filter" },
        [
          _c(
            "li",
            {
              key: "header",
              staticClass: "filter-section filter-section-main"
            },
            [
              _c("div", { staticClass: "filter-header" }, [
                _c("div", { staticClass: "filter-header-text" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("locale")("Filtrera resultatet")))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "filter-header-actions" }, [
                  _c("input", {
                    attrs: {
                      type: "button",
                      value: _vm._f("locale")("Återställ")
                    },
                    on: { click: _vm.onReset }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "filter-content" }, [
                _c(
                  "table",
                  [
                    _c("tr", [
                      _c("td", { staticClass: "filter-caption" }, [
                        _vm._v(_vm._s(_vm._f("locale")("Endast direktflyg")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "filter-label" },
                        [
                          _c("switch-button", {
                            ref: "directFlightButton",
                            attrs: { name: "filter_direct" },
                            on: { change: _vm.onChangeDirect }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", { staticClass: "filter-caption" }, [
                        _vm._v(
                          _vm._s(_vm._f("locale")("Inkl incheckat bagage"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "filter-label" },
                        [
                          _c("switch-button", {
                            attrs: { name: "filter_inc_bagage" },
                            on: { change: _vm.onChange }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", { staticClass: "filter-caption" }, [
                        _vm._v(_vm._s(_vm._f("locale")("Utan flygplatsbyte")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "filter-label" },
                        [
                          _c("switch-button", {
                            attrs: { name: "filter_airport_transfer" },
                            on: { change: _vm.onChangeAirportTransfer }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm.filterData
                      ? [
                          _c("tr", [
                            _c("td", { staticClass: "filter-caption" }, [
                              _vm._v(_vm._s(_vm._f("locale")("Total restid")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", { staticClass: "filter-label-group" }, [
                            _c("td", { staticClass: "filter-label" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("minutes_to_hours_and_minutes")(
                                    _vm.minTraveltime
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "filter-label" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("minutes_to_hours_and_minutes")(
                                    _vm.maxTraveltime
                                  )
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              { attrs: { colspan: "2" } },
                              [
                                _c("slider", {
                                  attrs: {
                                    name: "max_traveltime",
                                    value: _vm.filterData.max_traveltime,
                                    min: _vm.filterData.min_traveltime,
                                    max: _vm.filterData.max_traveltime,
                                    step: 1
                                  },
                                  on: {
                                    change: _vm.onChange,
                                    slide: _vm.maxTraveltimeChange
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm.filterData && _vm.filterData.legs
            ? _vm._l(_vm.filterData.legs, function(leg, legIndex) {
                return _c(
                  "search-filter-item",
                  {
                    key: "leg_" + legIndex,
                    attrs: { title: leg.from.city + " - " + leg.to.city }
                  },
                  [
                    _c("table", [
                      _c("tr", [
                        _c("td", { staticClass: "filter-caption" }, [
                          _vm._v(_vm._s(_vm._f("locale")("Antal byten")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "filter-label-group" }, [
                        _c("td", { staticClass: "filter-label" }, [
                          _vm._v(
                            _vm._s(_vm.minMaxStops[legIndex]) +
                              " " +
                              _vm._s(_vm._f("locale")("byten"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "filter-label" }, [
                          _vm._v(
                            _vm._s(_vm.maxMaxStops[legIndex]) +
                              " " +
                              _vm._s(_vm._f("locale")("byten"))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c(
                          "td",
                          { attrs: { colspan: "2" } },
                          [
                            _c("slider", {
                              attrs: {
                                name: "filter_stops[" + legIndex + "]",
                                value: _vm.maxMaxStops[legIndex],
                                context: legIndex,
                                min: _vm.filterData.min_stops[legIndex],
                                max: _vm.filterData.max_stops[legIndex]
                              },
                              on: {
                                "update:value": function($event) {
                                  return _vm.$set(
                                    _vm.maxMaxStops,
                                    legIndex,
                                    $event
                                  )
                                },
                                change: _vm.onChangeMaxStops,
                                slide: _vm.maxStopsChange
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "filter-caption" }, [
                          _vm._v(_vm._s(_vm._f("locale")("Restid")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "filter-label-group" }, [
                        _c("td", { staticClass: "filter-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("minutes_to_hours_and_minutes")(
                                _vm.minTraveltimeSeg[legIndex]
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "filter-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("minutes_to_hours_and_minutes")(
                                _vm.maxTraveltimeSeg[legIndex]
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c(
                          "td",
                          { attrs: { colspan: "2" } },
                          [
                            _c("slider", {
                              attrs: {
                                name:
                                  "filter_travel_time_leg[" + legIndex + "]",
                                value:
                                  _vm.filterData.max_traveltime_segments[
                                    legIndex
                                  ],
                                context: legIndex,
                                min:
                                  _vm.filterData.min_traveltime_segments[
                                    legIndex
                                  ],
                                max:
                                  _vm.filterData.max_traveltime_segments[
                                    legIndex
                                  ],
                                step: 1
                              },
                              on: {
                                change: _vm.onChange,
                                slide: _vm.maxTraveltimeSegChange
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c(
                          "td",
                          { attrs: { colspan: "2" } },
                          [
                            _c("time-range-slider", {
                              attrs: {
                                title: _vm.legTextAirportFrom("Avgång", leg),
                                "min-name":
                                  "filter_departure_time_min[" + legIndex + "]",
                                "max-name":
                                  "filter_departure_time_max[" + legIndex + "]",
                                min: _vm.filterDepartureTime[legIndex][0],
                                max: _vm.filterDepartureTime[legIndex][1],
                                dayNumber: leg.from_date_wday
                              },
                              on: { change: _vm.onChange }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c(
                          "td",
                          { attrs: { colspan: "2" } },
                          [
                            _c("time-range-slider", {
                              attrs: {
                                title: _vm.legTextAirportTo("Ankomst", leg),
                                "min-name":
                                  "filter_arrival_time_min[" + legIndex + "]",
                                "max-name":
                                  "filter_arrival_time_max[" + legIndex + "]",
                                min: _vm.filterArrivalTime[legIndex][0],
                                max: _vm.filterArrivalTime[legIndex][1],
                                dayNumber: leg.from_date_wday
                              },
                              on: { change: _vm.onChange }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ]
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.filterData && _vm.filterData.alliances
            ? _c(
                "search-filter-item",
                {
                  ref: "allicanceTab",
                  attrs: {
                    title: _vm._f("locale")("Allianser"),
                    selectLinks: ""
                  }
                },
                [
                  _c(
                    "ul",
                    { staticClass: "checkbox-list" },
                    _vm._l(_vm.filterData.alliances, function(alliance) {
                      return _c(
                        "li",
                        { key: "alliance_" + alliance[1] },
                        [
                          _c("checkbox", {
                            attrs: {
                              name: "alliance[" + alliance[1] + "]",
                              title: alliance[0],
                              checked: ""
                            },
                            on: { change: _vm.onChange }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "only-button",
                              on: {
                                click: function($event) {
                                  return _vm.onlyCheckbox(
                                    _vm.$refs.allicanceTab,
                                    "alliance[" + alliance[1] + "]"
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm._f("locale")("bara")))]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.filterData && _vm.filterData.airlines
            ? _c(
                "search-filter-item",
                {
                  ref: "airlineTab",
                  attrs: {
                    title: _vm._f("locale")("Flygbolag"),
                    selectLinks: ""
                  }
                },
                [
                  _c(
                    "ul",
                    { staticClass: "checkbox-list" },
                    _vm._l(_vm.filterData.airlines, function(airline, code) {
                      return _c(
                        "li",
                        { key: "airline_" + code },
                        [
                          _c("checkbox", {
                            attrs: {
                              name: "airline[" + code + "]",
                              title: airline,
                              checked: ""
                            },
                            on: { change: _vm.onChange }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "only-button",
                              on: {
                                click: function($event) {
                                  return _vm.onlyCheckbox(
                                    _vm.$refs.airlineTab,
                                    "airline[" + code + "]"
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm._f("locale")("bara")))]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.filterData && _vm.filterData.connection_points
            ? _c(
                "search-filter-item",
                {
                  ref: "connectionPointsTab",
                  attrs: {
                    title: _vm._f("locale")("Bytesflygplatser"),
                    selectLinks: ""
                  }
                },
                [
                  _c(
                    "ul",
                    { staticClass: "checkbox-list" },
                    _vm._l(_vm.filterData.connection_points, function(
                      title,
                      code
                    ) {
                      return _c(
                        "li",
                        { key: "connection_points_" + code },
                        [
                          _c("checkbox", {
                            attrs: {
                              name: "connection_points[" + code + "]",
                              title: title,
                              checked: ""
                            },
                            on: { change: _vm.onChange }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "only-button",
                              on: {
                                click: function($event) {
                                  return _vm.onlyCheckbox(
                                    _vm.$refs.connectionPointsTab,
                                    "connection_points[" + code + "]"
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm._f("locale")("bara")))]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.filterData && _vm.filterData.equipment_types
            ? _c(
                "search-filter-item",
                {
                  ref: "aircraftTab",
                  attrs: {
                    title: _vm._f("locale")("Flygplan"),
                    selectLinks: "",
                    closed: ""
                  }
                },
                [
                  _c(
                    "ul",
                    { staticClass: "checkbox-list" },
                    _vm._l(_vm.filterData.equipment_types, function(item) {
                      return _c(
                        "li",
                        { key: "equipment_type_" + item[0] },
                        [
                          _c("checkbox", {
                            attrs: {
                              name: "equipment_type[" + item[0] + "]",
                              title: item[1],
                              checked: ""
                            },
                            on: { change: _vm.onChange }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "only-button",
                              on: {
                                click: function($event) {
                                  return _vm.onlyCheckbox(
                                    _vm.$refs.aircraftTab,
                                    "equipment_type[" + item[0] + "]"
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm._f("locale")("bara")))]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.banner_filter
            ? _c("div", { staticClass: "filter_banner sv-SE" })
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
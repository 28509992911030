var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "itinerary-window",
      staticStyle: { height: "157px", display: "block" }
    },
    [
      _c(
        "ul",
        { staticClass: "itinerary" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.info.itinerary.data.attributes.segments, function(leg) {
            return _vm._l(leg, function(segment) {
              return _c(
                "li",
                {
                  key: "segment_" + segment.index + "_" + segment.md5key,
                  staticClass: "segment"
                },
                [
                  _c("div", { staticClass: "center" }, [
                    _vm._v(_vm._s(segment.index))
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.departure_airport))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.departure_time))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.arrival_airport))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.arrival_time))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "center" }, [
                    _vm._v(_vm._s(segment.leg_index))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "center" }, [
                    _vm._v(_vm._s(segment.part_index))
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.carrier))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.flightnumber))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.operating_carrier))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.operating_flightnumber))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.flight_class))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.cabin))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.farebasis))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.brand_name || " "))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.equipment_type))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.departure_terminal))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.arrival_terminal))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.flight_time))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.travel_time))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.meal_code))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.ndc))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(segment.md5key))])
                ]
              )
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.info.itinerary2
        ? [
            _c("div", { staticClass: "separator itinerary-separator" }),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "itinerary",
                staticStyle: { "padding-top": "10px" }
              },
              [
                _vm._m(1),
                _vm._v(" "),
                _vm._l(_vm.info.itinerary2.data.attributes.segments, function(
                  leg
                ) {
                  return _vm._l(leg, function(segment) {
                    return _c(
                      "li",
                      {
                        key: "segment_" + segment.index + "_" + segment.md5key,
                        staticClass: "segment"
                      },
                      [
                        _c("div", { staticClass: "center" }, [
                          _vm._v(_vm._s(segment.index))
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.departure_airport))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.departure_time))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.arrival_airport))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.arrival_time))]),
                        _vm._v(" "),
                        _c("div", { staticClass: "center" }, [
                          _vm._v(_vm._s(segment.leg_index))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "center" }, [
                          _vm._v(_vm._s(segment.part_index))
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.carrier))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.flightnumber))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.operating_carrier))]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(_vm._s(segment.operating_flightnumber))
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.flight_class))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.cabin))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.farebasis))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.brand_name || " "))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.equipment_type))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.departure_terminal))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.arrival_terminal))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.flight_time))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.travel_time))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.meal_code))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(segment.md5key))])
                      ]
                    )
                  })
                })
              ],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "segment-header" }, [
      _c("div", { staticClass: "div1" }, [_vm._v("#")]),
      _vm._v(" "),
      _c("div", [_vm._v("Dep. airport")]),
      _vm._v(" "),
      _c("div", [_vm._v("Dep. time")]),
      _vm._v(" "),
      _c("div", [_vm._v("Arr. airport")]),
      _vm._v(" "),
      _c("div", [_vm._v("Arr. time")]),
      _vm._v(" "),
      _c("div", { staticClass: "div6" }, [_vm._v("Leg")]),
      _vm._v(" "),
      _c("div", { staticClass: "div6" }, [_vm._v("Part")]),
      _vm._v(" "),
      _c("div", [_vm._v("Carrier")]),
      _vm._v(" "),
      _c("div", [_vm._v("Flightno.")]),
      _vm._v(" "),
      _c("div", [_vm._v("Op. carrier")]),
      _vm._v(" "),
      _c("div", [_vm._v("Op. flightno.")]),
      _vm._v(" "),
      _c("div", [_vm._v("Class")]),
      _vm._v(" "),
      _c("div", [_vm._v("Cabin")]),
      _vm._v(" "),
      _c("div", [_vm._v("Farebasis")]),
      _vm._v(" "),
      _c("div", [_vm._v("Brand")]),
      _vm._v(" "),
      _c("div", [_vm._v("Equipment")]),
      _vm._v(" "),
      _c("div", [_vm._v("Dep. term.")]),
      _vm._v(" "),
      _c("div", [_vm._v("Arr. term.")]),
      _vm._v(" "),
      _c("div", [_vm._v("Flight time")]),
      _vm._v(" "),
      _c("div", [_vm._v("Travel time")]),
      _vm._v(" "),
      _c("div", [_vm._v("Meal")]),
      _vm._v(" "),
      _c("div", [_vm._v("Ndc segment")]),
      _vm._v(" "),
      _c("div", [_vm._v("MD5")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "segment-header" }, [
      _c("div", { staticClass: "div1" }, [_vm._v("#")]),
      _vm._v(" "),
      _c("div", [_vm._v("Dep. airport")]),
      _vm._v(" "),
      _c("div", [_vm._v("Dep. time")]),
      _vm._v(" "),
      _c("div", [_vm._v("Arr. airport")]),
      _vm._v(" "),
      _c("div", [_vm._v("Arr. time")]),
      _vm._v(" "),
      _c("div", { staticClass: "div6" }, [_vm._v("Leg")]),
      _vm._v(" "),
      _c("div", { staticClass: "div6" }, [_vm._v("Part")]),
      _vm._v(" "),
      _c("div", [_vm._v("Carrier")]),
      _vm._v(" "),
      _c("div", [_vm._v("Flightno.")]),
      _vm._v(" "),
      _c("div", [_vm._v("Op. carrier")]),
      _vm._v(" "),
      _c("div", [_vm._v("Op. flightno.")]),
      _vm._v(" "),
      _c("div", [_vm._v("Class")]),
      _vm._v(" "),
      _c("div", [_vm._v("Cabin")]),
      _vm._v(" "),
      _c("div", [_vm._v("Farebasis")]),
      _vm._v(" "),
      _c("div", [_vm._v("Brand")]),
      _vm._v(" "),
      _c("div", [_vm._v("Equipment")]),
      _vm._v(" "),
      _c("div", [_vm._v("Dep. term.")]),
      _vm._v(" "),
      _c("div", [_vm._v("Arr. term.")]),
      _vm._v(" "),
      _c("div", [_vm._v("Flight time")]),
      _vm._v(" "),
      _c("div", [_vm._v("Travel time")]),
      _vm._v(" "),
      _c("div", [_vm._v("Meal")]),
      _vm._v(" "),
      _c("div", [_vm._v("MD5")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", [
    _c("tr", [
      _c("td", {
        staticClass: "filter-caption",
        domProps: { innerHTML: _vm._s(_vm.title) }
      })
    ]),
    _vm._v(" "),
    _c("tr", [
      _c(
        "td",
        { staticClass: "filter-label", staticStyle: { "text-align": "left" } },
        [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("minutes_to_clock_with_day")(
                  _vm.currentValues[0],
                  _vm.dayNumber
                )
              ) +
              "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c("td", { staticClass: "filter-label" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm._f("minutes_to_clock_with_day")(
                _vm.currentValues[1],
                _vm.dayNumber
              )
            ) +
            "\n    "
        )
      ])
    ]),
    _vm._v(" "),
    _c("tr", [
      _c(
        "td",
        { attrs: { colspan: "2" } },
        [
          _c("multi-slider", {
            ref: "slider",
            attrs: {
              "min-name": _vm.minName,
              "max-name": _vm.maxName,
              values: _vm.currentValues,
              min: _vm.min,
              max: _vm.max,
              step: _vm.step
            },
            on: { change: _vm.onChange, slide: _vm.onSlide }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: _vm.componentID() } }, [
    _c("input", {
      attrs: { type: "hidden", name: this.minName },
      domProps: { value: this.currentValues[0] }
    }),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "hidden", name: this.maxName },
      domProps: { value: this.currentValues[1] }
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "slider_component",
      class: { "ui-slider-max-value": _vm.min == _vm.max }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
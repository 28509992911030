var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pricing-window", staticStyle: { height: "100%" } },
    [
      _c("div", { staticClass: "pricing-row" }, [
        _vm.info.itinerary
          ? _c("div", { staticClass: "total-pricing-window" }, [
              _c(
                "ul",
                { staticClass: "total-pricing" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._l(
                    _vm.info.itinerary.data.attributes.price_list,
                    function(part) {
                      return _c(
                        "li",
                        {
                          key: "part_" + part.title,
                          class: {
                            line: part.title !== "Total",
                            sumline: part.title === "Total"
                          }
                        },
                        [
                          _c("div", [_vm._v(_vm._s(part.title))]),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(part.price_type))]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              _vm._s(part.net_price) +
                                " " +
                                _vm._s(part.currency) +
                                " "
                            ),
                            part.ex_net_price
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(part.ex_net_price) +
                                      " " +
                                      _vm._s(
                                        _vm.info.itinerary.data.attributes
                                          .currency
                                      ) +
                                      ")"
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(part.ex_rate))]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              part.tax
                                ? [
                                    _vm._v(
                                      _vm._s(part.tax) +
                                        " " +
                                        _vm._s(part.currency)
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              part.sales_price
                                ? [
                                    _vm._v(
                                      _vm._s(part.sales_price) +
                                        " " +
                                        _vm._s(part.currency)
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              part.profit
                                ? [
                                    _vm._v(
                                      _vm._s(part.profit) +
                                        " " +
                                        _vm._s(part.currency)
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(part.pricerule))]),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(part.searchrule))])
                        ]
                      )
                    }
                  )
                ],
                2
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "unit-pricing-window" }, [
          _c(
            "ul",
            { staticClass: "unit-pricing" },
            [
              _vm._m(1),
              _vm._v(" "),
              _vm._l(
                _vm.info.itinerary.data.attributes.unit_price_list,
                function(item) {
                  return _c("li", { key: item.title, staticClass: "line" }, [
                    _c("div", [_vm._v(_vm._s(item.title))]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(item.fare) + " " + _vm._s(item.currency) + " "
                      ),
                      item.ex_net_price
                        ? _c("span", [
                            _vm._v("(" + _vm._s(item.ex_net_price) + ")")
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(_vm._s(item.tax) + " " + _vm._s(item.currency))
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(item.net_price) + " " + _vm._s(item.currency)
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(item.sales_price) + " " + _vm._s(item.currency)
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(_vm._s(item.profit) + " " + _vm._s(item.currency))
                    ])
                  ])
                }
              )
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _vm.info.itinerary2
        ? [
            _c("div", { staticClass: "separator itinerary-separator" }),
            _vm._v(" "),
            _c("div", { staticClass: "pricing-row" }, [
              _c("div", { staticClass: "total-pricing-window" }, [
                _c(
                  "ul",
                  { staticClass: "total-pricing" },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _vm._l(
                      _vm.info.itinerary2.data.attributes.price_list,
                      function(part) {
                        return _c(
                          "li",
                          {
                            key: "part_" + part.title,
                            class: {
                              line: part.title !== "Total",
                              sumline: part.title === "Total"
                            }
                          },
                          [
                            _c("div", [_vm._v(_vm._s(part.title))]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(part.price_type))]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(part.net_price) +
                                  " " +
                                  _vm._s(part.currency)
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(part.ex_rate))]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                part.tax
                                  ? [
                                      _vm._v(
                                        _vm._s(part.tax) +
                                          " " +
                                          _vm._s(part.currency)
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                part.sales_price
                                  ? [
                                      _vm._v(
                                        _vm._s(part.sales_price) +
                                          " " +
                                          _vm._s(part.currency)
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                part.profit
                                  ? [
                                      _vm._v(
                                        _vm._s(part.profit) +
                                          " " +
                                          _vm._s(part.currency)
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(part.pricerule))]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(part.searchrule))])
                          ]
                        )
                      }
                    )
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "unit-pricing-window" }, [
                _c(
                  "ul",
                  { staticClass: "unit-pricing" },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _vm._l(
                      _vm.info.itinerary2.data.attributes.unit_price_list,
                      function(item) {
                        return _c(
                          "li",
                          { key: item.title, staticClass: "line" },
                          [
                            _c("div", [_vm._v(_vm._s(item.title))]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(item.fare) +
                                  " " +
                                  _vm._s(item.currency) +
                                  " "
                              ),
                              item.ex_net_price
                                ? _c("span", [
                                    _vm._v(
                                      "(" + _vm._s(item.ex_net_price) + ")"
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(item.tax) + " " + _vm._s(item.currency)
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(item.net_price) +
                                  " " +
                                  _vm._s(item.currency)
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(item.sales_price) +
                                  " " +
                                  _vm._s(item.currency)
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(item.profit) +
                                  " " +
                                  _vm._s(item.currency)
                              )
                            ])
                          ]
                        )
                      }
                    )
                  ],
                  2
                )
              ])
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "line-header" }, [
      _c("div", [_vm._v("Description")]),
      _vm._v(" "),
      _c("div", [_vm._v("Type")]),
      _vm._v(" "),
      _c("div", [_vm._v("Cost")]),
      _vm._v(" "),
      _c("div", [_vm._v("Ex. rate")]),
      _vm._v(" "),
      _c("div", [_vm._v("Vat")]),
      _vm._v(" "),
      _c("div", [_vm._v("Sales")]),
      _vm._v(" "),
      _c("div", [_vm._v("Profit")]),
      _vm._v(" "),
      _c("div", [_vm._v("Pricerule")]),
      _vm._v(" "),
      _c("div", [_vm._v("Searchrule")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "line-header" }, [
      _c("div", [_vm._v("Passenger type")]),
      _vm._v(" "),
      _c("div", [_vm._v("Fare")]),
      _vm._v(" "),
      _c("div", [_vm._v("Tax")]),
      _vm._v(" "),
      _c("div", [_vm._v("Cost")]),
      _vm._v(" "),
      _c("div", [_vm._v("Sales")]),
      _vm._v(" "),
      _c("div", [_vm._v("Profit")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "line-header" }, [
      _c("div", [_vm._v("Description")]),
      _vm._v(" "),
      _c("div", [_vm._v("Type")]),
      _vm._v(" "),
      _c("div", [_vm._v("Cost")]),
      _vm._v(" "),
      _c("div", [_vm._v("Ex. rate")]),
      _vm._v(" "),
      _c("div", [_vm._v("Vat")]),
      _vm._v(" "),
      _c("div", [_vm._v("Sales")]),
      _vm._v(" "),
      _c("div", [_vm._v("Profit")]),
      _vm._v(" "),
      _c("div", [_vm._v("Pricerule")]),
      _vm._v(" "),
      _c("div", [_vm._v("Searchrule")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "line-header" }, [
      _c("div", [_vm._v("Passenger type")]),
      _vm._v(" "),
      _c("div", [_vm._v("Fare")]),
      _vm._v(" "),
      _c("div", [_vm._v("Tax")]),
      _vm._v(" "),
      _c("div", [_vm._v("Cost")]),
      _vm._v(" "),
      _c("div", [_vm._v("Sales")]),
      _vm._v(" "),
      _c("div", [_vm._v("Profit")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
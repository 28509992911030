var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticStyle: { "font-size": "0" } }, [
        _c("div", { staticClass: "block1" }, [
          _c(
            "table",
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(
                _vm.info.itinerary.data.attributes.baggage_ancillaries,
                function(collection) {
                  return [
                    _vm._l(collection, function(paxbags) {
                      return _vm._l(paxbags.ancillaries, function(ancillary) {
                        return _c(
                          "tr",
                          {
                            key: "bag_" + ancillary.id,
                            staticClass: "border-b-1"
                          },
                          [
                            _c("td", [
                              _vm._v(
                                _vm._s(paxbags.pax_type) +
                                  " " +
                                  _vm._s(paxbags.pax_number)
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(paxbags.leg_number))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(ancillary.price))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(ancillary.net_price))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(ancillary.id))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  ancillary.pricerule_id[
                                    ancillary.key.toLowerCase()
                                  ]
                                )
                              )
                            ])
                          ]
                        )
                      })
                    })
                  ]
                }
              )
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "separator" }),
          _vm._v(" "),
          _c(
            "table",
            { staticStyle: { "border-top": "1px solid #7f7f7f" } },
            [
              _vm._m(1),
              _vm._v(" "),
              _vm._l(_vm.info.itinerary.data.attributes.ancillaries, function(
                anc
              ) {
                return _c(
                  "tr",
                  {
                    key:
                      "anc_" +
                      anc.part_index +
                      "_" +
                      anc.code +
                      "_" +
                      anc.amount,
                    staticClass: "border-b-1"
                  },
                  [
                    _c("td", [_vm._v("#" + _vm._s(anc.part_index))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(anc.code))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(anc.amount))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(anc.carrier))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(anc.description))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(anc.passenger_code))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "from " +
                          _vm._s(anc.start_segment) +
                          " to " +
                          _vm._s(anc.end_segment)
                      )
                    ])
                  ]
                )
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "block2" }, [
          _c(
            "table",
            [
              _vm._m(2),
              _vm._v(" "),
              _vm._l(_vm.info.itinerary.data.attributes.bags_included, function(
                leg
              ) {
                return _c(
                  "tr",
                  {
                    key:
                      "anc_bag_" +
                      leg.leg_index +
                      "_" +
                      leg.flight_index +
                      "_" +
                      leg.type
                  },
                  [
                    _c("td", [_vm._v(_vm._s(leg.leg_index))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(leg.flight_index))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(leg.type))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(leg.text))])
                  ]
                )
              })
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _vm.info.itinerary2
        ? [
            _c("div", { staticClass: "separator itinerary-separator" }),
            _vm._v(" "),
            _c("div", { staticStyle: { "font-size": "0" } }, [
              _c("div", { staticClass: "block1" }, [
                _c(
                  "table",
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _vm._l(_vm.info.itinerary2.data.attributes.bags, function(
                      paxbag
                    ) {
                      return _vm._l(paxbag.items, function(bag) {
                        return _c(
                          "tr",
                          {
                            key: "bag_" + paxbag.pax_no + "_" + bag.leg_no,
                            staticClass: "border-b-1"
                          },
                          [
                            _c("td", [_vm._v(_vm._s(paxbag.text))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(bag.leg_no))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(bag.sales_price))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(bag.sales_price_per_pax))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(bag.cost_price))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(bag.cost_price_per_pax))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(bag.text))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(bag.pricerule))])
                          ]
                        )
                      })
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "separator" }),
                _vm._v(" "),
                _c(
                  "table",
                  { staticStyle: { "border-top": "1px solid #7f7f7f" } },
                  [
                    _vm._m(4),
                    _vm._v(" "),
                    _vm._l(
                      _vm.info.itinerary2.data.attributes.ancillaries,
                      function(anc) {
                        return _c(
                          "tr",
                          {
                            key:
                              "anc_" +
                              anc.part_index +
                              "_" +
                              anc.code +
                              "_" +
                              anc.amount,
                            staticClass: "border-b-1"
                          },
                          [
                            _c("td", [_vm._v("#" + _vm._s(anc.part_index))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(anc.code))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(anc.amount))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(anc.carrier))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(anc.description))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(anc.passenger_code))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "from " +
                                  _vm._s(anc.start_segment) +
                                  " to " +
                                  _vm._s(anc.end_segment)
                              )
                            ])
                          ]
                        )
                      }
                    )
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "block2" }, [
                _c(
                  "table",
                  [
                    _vm._m(5),
                    _vm._v(" "),
                    _vm._l(
                      _vm.info.itinerary2.data.attributes.bags_included,
                      function(leg) {
                        return _c(
                          "tr",
                          {
                            key:
                              "anc_bag_" +
                              leg.leg_index +
                              "_" +
                              leg.flight_index +
                              "_" +
                              leg.type
                          },
                          [
                            _c("td", [_vm._v(_vm._s(leg.leg_index))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(leg.flight_index))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(leg.type))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(leg.text))])
                          ]
                        )
                      }
                    )
                  ],
                  2
                )
              ])
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticStyle: { "border-bottom": "1px solid #7f7f7f" } }, [
      _c("th", [_vm._v("Passenger")]),
      _vm._v(" "),
      _c("th", [_vm._v("Leg")]),
      _vm._v(" "),
      _c("th", [_vm._v("Sales Price")]),
      _vm._v(" "),
      _c("th", [_vm._v("Cost Price")]),
      _vm._v(" "),
      _c("th", [_vm._v("Description")]),
      _vm._v(" "),
      _c("th", [_vm._v("Pricerule")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticStyle: { "border-bottom": "1px solid #7f7f7f" } }, [
      _c("th", [_vm._v("Part")]),
      _vm._v(" "),
      _c("th", [_vm._v("Code")]),
      _vm._v(" "),
      _c("th", [_vm._v("Amount")]),
      _vm._v(" "),
      _c("th", [_vm._v("Carrier")]),
      _vm._v(" "),
      _c("th", [_vm._v("Description")]),
      _vm._v(" "),
      _c("th", [_vm._v("Passenger")]),
      _vm._v(" "),
      _c("th", [_vm._v("Leg")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticStyle: { "border-bottom": "1px solid #7f7f7f" } }, [
      _c("th", [_vm._v("Resa")]),
      _vm._v(" "),
      _c("th", [_vm._v("Leg")]),
      _vm._v(" "),
      _c("th", [_vm._v("Bag")]),
      _vm._v(" "),
      _c("th", [_vm._v("Text")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticStyle: { "border-bottom": "1px solid #7f7f7f" } }, [
      _c("th", [_vm._v("Passenger")]),
      _vm._v(" "),
      _c("th", [_vm._v("Leg")]),
      _vm._v(" "),
      _c("th", [_vm._v("Sales Price")]),
      _vm._v(" "),
      _c("th", [_vm._v("Sales Price per pax")]),
      _vm._v(" "),
      _c("th", [_vm._v("Cost Price")]),
      _vm._v(" "),
      _c("th", [_vm._v("Cost Price per pax")]),
      _vm._v(" "),
      _c("th", [_vm._v("Description")]),
      _vm._v(" "),
      _c("th", [_vm._v("Pricerule")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticStyle: { "border-bottom": "1px solid #7f7f7f" } }, [
      _c("th", [_vm._v("Part")]),
      _vm._v(" "),
      _c("th", [_vm._v("Code")]),
      _vm._v(" "),
      _c("th", [_vm._v("Amount")]),
      _vm._v(" "),
      _c("th", [_vm._v("Carrier")]),
      _vm._v(" "),
      _c("th", [_vm._v("Description")]),
      _vm._v(" "),
      _c("th", [_vm._v("Passenger")]),
      _vm._v(" "),
      _c("th", [_vm._v("Leg")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticStyle: { "border-bottom": "1px solid #7f7f7f" } }, [
      _c("th", [_vm._v("Resa")]),
      _vm._v(" "),
      _c("th", [_vm._v("Leg")]),
      _vm._v(" "),
      _c("th", [_vm._v("Bag")]),
      _vm._v(" "),
      _c("th", [_vm._v("Text")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
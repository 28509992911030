var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { attrs: { id: _vm.componentID() } }, [
    _c(
      "span",
      {
        staticClass: "checkbox-compoent",
        class: { selected: _vm.currentChecked }
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentChecked,
              expression: "currentChecked"
            }
          ],
          attrs: { type: "checkbox", name: _vm.name, value: "1" },
          domProps: {
            checked: Array.isArray(_vm.currentChecked)
              ? _vm._i(_vm.currentChecked, "1") > -1
              : _vm.currentChecked
          },
          on: {
            change: function($event) {
              var $$a = _vm.currentChecked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = "1",
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.currentChecked = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.currentChecked = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.currentChecked = $$c
              }
            }
          }
        })
      ]
    ),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.title))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
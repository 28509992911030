var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.panelEnabled
      ? _c(
          "div",
          {
            staticClass: "debug-panel",
            style: { height: _vm.panelHeight + "px" }
          },
          [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "tabs" }, [
                _c(
                  "ul",
                  _vm._l(_vm.tabComponents, function(tabComp) {
                    return _c(
                      "li",
                      {
                        key: "tab_" + tabComp.tabOptions.title,
                        staticClass: "debug-tab",
                        class: { active: _vm.activeTab === tabComp },
                        on: {
                          click: function($event) {
                            return _vm.tabClicked(tabComp)
                          }
                        }
                      },
                      [_vm._v(_vm._s(tabComp.tabOptions.title))]
                    )
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "extra_options" }, [
                _c(
                  "span",
                  { staticStyle: { margin: "0 20px" } },
                  [
                    _vm._v(
                      "\n          Showing " +
                        _vm._s(_vm.$store.state.flightItinerariesCount) +
                        " of " +
                        _vm._s(_vm.$store.state.flightItinerariesCountMax) +
                        " results\n          "
                    ),
                    _vm.$store.state.flightItinerariesCountRemoved &&
                    _vm.$store.state.flightItinerariesCountRemoved > 0
                      ? [
                          _vm._v(
                            "(" +
                              _vm._s(
                                _vm.$store.state.flightItinerariesCountRemoved
                              ) +
                              " removed)"
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.debugData
                  ? _c("label", [_vm._v("uuid: " + _vm._s(_vm.debugData.uuid))])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://admin.travex.se/logs/searches/" +
                        this.searchId,
                      target: "_blank"
                    }
                  },
                  [_vm._v("Open in Falco")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "controls" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: { click: _vm.hidePanel }
                  },
                  [_vm._v("hide")]
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "debug-info",
                staticStyle: { "background-color": "#e8e8e8" }
              },
              [
                _vm.tabComponents.length === 0
                  ? _c("div", { staticClass: "debug-info-message" }, [
                      _vm._v(
                        "\n        Move the mouse over an itinerary to show the debug information.\n      "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeTab
                  ? _c(
                      "keep-alive",
                      [
                        _c(_vm.activeTab, {
                          tag: "component",
                          attrs: { info: _vm.debugData }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "ui-resizable-handle ui-resizable-n",
              staticStyle: { "z-index": "90" },
              on: { mousedown: _vm.panelMouseDown }
            })
          ]
        )
      : _c("div", [
          _c(
            "div",
            { staticClass: "debug-button", on: { click: _vm.showPanel } },
            [_vm._v("Debug")]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
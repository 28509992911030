var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$store.state.alternativeItinerary
        ? [
            _c("itinerary-air-alternative", {
              attrs: { itinerary: _vm.$store.state.alternativeItinerary }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.itineraries &&
      _vm.itineraries.length === 0 &&
      !_vm.$store.getters.searching &&
      !_vm.loading
        ? _c("div", [
            _vm._v(
              "\n    " +
                _vm._s(_vm._f("locale")("Alla resultat är bortfiltrerade.")) +
                " "
            ),
            _c(
              "a",
              {
                attrs: { id: "reset-filter", href: "#" },
                on: { click: _vm.resetFilter }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("locale")("Klicka här för att rensa alla filter")
                  )
                )
              ]
            ),
            _vm._v(".\n  ")
          ])
        : _c(
            "div",
            [
              _vm._l(_vm.itineraries, function(itinerary, index) {
                return [
                  itinerary.attributes.itinerary_type == "hotel"
                    ? _c("itinerary-hotel", {
                        key: itinerary.id,
                        attrs: { itinerary: itinerary }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasBanners && index % 4 == 0
                    ? _c(
                        "div",
                        {
                          key: "banner_" + index,
                          staticClass: "banners_horizontal"
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href:
                                  _vm.banners[(index / 4) % _vm.banners.length]
                                    .link
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm._f("cloudinary")(
                                    "/assets/banners_horizontal/" +
                                      _vm.banners[
                                        (index / 4) % _vm.banners.length
                                      ].image
                                  )
                                }
                              })
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
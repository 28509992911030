var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "filter-section filter-collapsable",
      class: { "filter-open": _vm.open, "filter-closed": !_vm.open },
      attrs: { id: _vm.componentID() }
    },
    [
      _c(
        "a",
        {
          staticClass: "filter-header",
          attrs: { href: "javascript:;" },
          on: { click: _vm.toggleOpen }
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _vm.selectLinks
        ? _c("div", { staticClass: "actions" }, [
            _c(
              "a",
              { attrs: { href: "javascript:;" }, on: { click: _vm.selectAll } },
              [_vm._v(_vm._s(_vm._f("locale")("alla")))]
            ),
            _vm._v(" / "),
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: { click: _vm.unselectAll }
              },
              [_vm._v(_vm._s(_vm._f("locale")("ingen")))]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "filter-content" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
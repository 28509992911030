var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { padding: "10px" } }, [
    _c("p", [_vm._v("Debug information")]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "table",
      _vm._l(Object.keys(_vm.state), function(key) {
        return _c("tr", { key: key }, [
          _c("td", { staticStyle: { "padding-right": "10px" } }, [
            _vm._v(_vm._s(key))
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.tryGetValue(key)))])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }